// for radius

.mdc-text-field--outlined {
  .mdc-notched-outline__leading,
  .mdc-notched-outline__trailing,
  .mdc-notched-outline__notch {
    border-color: $borderformColor;
  }

  .mdc-notched-outline .mdc-notched-outline__leading {
    border-top-left-radius: $border-radius;
    border-bottom-left-radius: $border-radius;
    width: $border-radius;
  }

  .mdc-notched-outline .mdc-notched-outline__trailing {
    border-top-right-radius: $border-radius;
    border-bottom-right-radius: $border-radius;
  }
}

.mdc-form-field > label {
  font-size: 14px;
}

.hide-hint {
  .mat-mdc-form-field-subscript-wrapper {
    display: none;
  }
}

.mat-mdc-dialog-container .mdc-dialog__surface {
  border-radius: $border-radius !important;
}

.mat-mdc-form-field-infix {
  min-height: 42px !important;
  padding: 11px 0 !important;
}

.mat-mdc-select-arrow-wrapper {
  height: 20px !important;
}

.mat-mdc-form-field-flex {
  .mat-icon {
    height: 20px !important;
    width: 20px !important;
  }

  .material-icons {
    line-height: 0 !important;
  }
}
