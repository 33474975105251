*,
:after,
:before {
  box-sizing: border-box;
}

body {
  font-family: $font-family;
  color: $textPrimary;
  font-size: 14px;
  line-height: 1.334rem;
}

html .mat-drawer-container {
  background-color: $background;
}

.mainWrapper {
  display: flex;
  min-height: 100vh;
  width: 100%;
  flex: 1;
  height: 100%;
}

.pageWrapper {
  padding-top: $layout-padding;
  min-height: calc(100vh - 70px);

  margin: 0 auto;
  &.maxWidth {
    max-width: $boxedWidth;
  }
}

.w-100 {
  width: 100%;
}

.h-100 {
  height: 100%;
}

.shadow-none {
  box-shadow: none !important;
}

.rounded {
  border-radius: $border-radius !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: 25px !important;
}

.overflow-hidden {
  overflow: hidden;
}

.text-decoration-none {
  text-decoration: none;
}

.position-relative {
  position: relative;
}

.table-responsive {
  overflow-x: auto;
  display: block;

  td,
  mat-cell {
    white-space: nowrap;
    padding: 16px;
    text-align: center;
  }

  th,
  mat-header-cell {
    white-space: nowrap;
    font-size: 13px !important;
    text-align: center;
  }
}

.d-initial {
  display: initial !important;
}

.d-table {
  display: table !important;
}

.op-5 {
  opacity: 0.5;
}

.cursor-pointer {
  cursor: pointer;
}

.avatar-group {
  img {
    border: 2px solid white;
    margin-right: -5px;

    &:last-child {
      margin-right: 0;
    }
  }
}

.text-hover-primary:hover {
  .hover-text {
    color: $primary;
  }
}

.filter-sidebar {
  width: 350px !important;
}

/* tabular format */
.new_box_table {
  /*max-height: calc(100vh - 350px);*/
  /*max-height: calc(100vh - 265px);*/
  max-height: calc(100vh - 218px);
  overflow-y: auto;
  overflow-x: hidden;

  &::-webkit-scrollbar-track {
    border-radius: 7px;
    background-color: #fff;
  }

  &::-webkit-scrollbar {
    width: 5px;
    height: 5px;
    background-color: #fff;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 7px;
    background-color: rgba(0, 0, 0, .2);
  }
}

.new_box_table table {
  border-collapse: collapse;
  // width: 100%;
}

.new_box_table thead {
  position: sticky;
  top: -1px;
  z-index: 99;
}

.new_box_table td {
  border: 1px solid #dddddd;
  text-align: left;
  position: relative;
  padding: 0 0 0 10px;
}

.new_box_table th {
  border: 1px solid #dddddd;
  text-align: left;
  background: #0085db;
  padding: 10px 10px;
  color: #fff;
}

.new_box_table tr:nth-child(odd) {
  background-color: #dddddd38;
}

.f_input_table {
  width: 100%;
  border: 0px solid #ccc;
  background: none;
  font-size: 14px !important;
  /*padding: 15px 10px;*/
  padding: 10px 10px;
  /*border-radius: 10px;*/
  font-family: "Plus Jakarta Sans", sans-serif;

  &:focus-visible {
    outline: 1px solid #000 !important;
  }
}

.f_lable_table {
  float: left;
  margin: 0 0 3px 0;
}

.label_disabled {
  color: green;
  font-weight: bold;
}
/**/

  
@media screen and (max-width: 450px) {
  
  .filter-sidebar {
    width: 100vw !important;
  }
}