/* You can add global styles to this file, and also import other style files */

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

// .custom-table tbody tr th.bg-sticky {
//   z-index: 99999999 !important;
// }

// .custom-table tbody tr td {
//   z-index: 0 !important;
// }

.f_right_sheet_content {
  border-bottom: 1px solid #e9e9e9;
  transition: 0.3s all ease;
  cursor: pointer;
}

.overlay_f {
  width: 100% !important;
  height: 100vh;
  background: transparent !important;
}

.on_click_close {
 // width: 77% !important;
  width: calc(100% - 349.5px);
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  background: #00000069 !important;
}

.f_bg_white_sidebar {
  width: 350px;
  float: right;
  background: #fff;
  // height: 750px;
  height: 100vh;
  overflow-y: scroll;
}

.f_right_sheet_content:hover {
  background: #e5f3fb;
}

.f_right_sheet_content_text_bottom {
  color: #8f8f8f;
  width: 100%;
  display: block;
}

.f_right_sheet_content_text span {
  width: 100%;
  display: block;
}

.f_right_sheet_content img {
  height: 40px;
}

.custom-table {

  thead tr,
  tfoot tr,
  tbody tr {
    height: 37px !important;
  }

  thead tr th,
  tfoot tr td {
    padding: 8px !important;
    font-size: 13px !important;
  }

  tbody tr td {
    padding: 8px !important;
    font-size: 13px !important;
  }


}

/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, .2);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background-color: rgba(0, 0, 0, .2);
}

.fix-on-top{
  position: sticky;
  top: 0;
  background: white;
}

span.no-data-found {
  color: red;
  text-align: center;
  display: block;
  font-weight: 500;
  margin-top: 20px;
  height: 80vh;
}

span.mdc-evolution-chip__text-label.mat-mdc-chip-action-label {
  font-size: 12px !important;
}


@media screen and (max-width: 450px) {

.on_click_close {
   width: 0;
 }
 
 .f_bg_white_sidebar {
   width: 100%;
 }
}